<template>
  <sd-cards class="company-card" headless>
    <contact-card-wrapper>
      <div class="contact-card">
        <figure>
          <figcaption>
            <h3>{{ company.name }}</h3>
            <a-rate v-model:value="rating" disabled/>
          </figcaption>
        </figure>
        <div class="user-info">
          <ul>
            <li>
              <sdFeatherIcons type="phone" size="16" />
              {{ phoneNumber ? phoneNumber : '--'}}
            </li>
            <li>
              <sd-feather-icons type="map-pin" size="16" />
              {{ location ? location : '--'}}
            </li>
            <li>
              <sd-feather-icons type="briefcase" size="16" />
              {{ company.type ? company.type : '--'}}
            </li>
            <li v-if="company.status">
              <sd-feather-icons type="activity" size="16" />
              {{$t(`models.company.status.${company.status}`)}}
            </li>
            <li v-if="company.status === 'rejected' && company.rejectionReason" >
              {{'(' + $t(`models.company.rejectionReasons.${company.rejectionReason}`) + ')'}}
            </li>
            <li v-if="company.status === 'blacklisted' && company.blacklistComment">
              <sd-feather-icons type="edit-2" size="16" />
              {{company.blacklistComment}}
            </li>
          </ul>
        </div>
      </div>
    </contact-card-wrapper>
  </sd-cards>
</template>

<script>
import PropTypes from 'vue-types';
import {ContactCardWrapper} from './style';
import {computed, defineComponent} from 'vue';
import {getCityByCode} from '@/helpers/citiesOptions';
import _ from 'lodash';

export default defineComponent({
  name: 'CompanyCard',
  components: {ContactCardWrapper},
  props: {
    company: PropTypes.object
  },
  setup(props) {
    const rating = computed(() => {
      return props.company.overallRating;
    });

    const phoneNumber = computed(() => {
      let pn = '';

      if (props.company.phoneCode) {
        pn = `(${props.company.phoneCode}) `;
      }

      if (props.company.phone) {
        pn = pn + props.company.phone;
      }

      return pn;
    });

    const location = computed(() => {
      let loc = '';

      if (!_.isEmpty(props.company.countryLabel)) {
        loc = props.company.countryLabel;
      }

      if (!_.isEmpty(props.company.city)) {
        if (!_.isEmpty(loc)) {
          loc = loc + ', ';
        }
        const cityName = getCityByCode(props.company?.country, props.company?.city);
        loc = loc + cityName;
      }

      if (!_.isEmpty(props.company.street)) {
        if (!_.isEmpty(loc)) {
          loc = loc + ', ';
        }
        loc = loc + props.company.street;
      }

      return loc;
    });

    return {
      rating,
      phoneNumber,
      location
    };
  }
});

</script>

<style lang="scss" scoped>
.company-card {
  height: 92% !important;
}

h3 {
  overflow-wrap: anywhere !important;
  max-height: 60px;
  overflow: hidden;
}

.company-status {
  display: block ruby;
}
</style>
