<template>
  <sd-cards headless>
    <contact-card-wrapper>
      <div class="contact-card">
        <figure>
          <figcaption>
            <h3>{{ $t('models.company.attributes.countriesCoverage') }}</h3>
          </figcaption>
        </figure>
        <div class="user-info">
          <ul>
            <li v-for="country in data" :key="country">
              <sd-heading as="h5" class="price-list-download">
                <template v-if="canDownloadOrPreview">
                  <a-tooltip placement="left" :title="$t('actions.priceListDownload')">
                    <a-button type="link" @click="download(country)">
                      <template #icon><sdFeatherIcons type="download" /></template>
                    </a-button>
                  </a-tooltip>
                </template>
                <template v-if="canDownloadOrPreview">
                  <a-tooltip placement="left" :title="$t('actions.priceListPreview')">
                    <a-button type="link"
                    :loading="loading && currentCountryValue === country.value"
                    :disabled="loading && currentCountryValue === country.value"
                    @click="preview(country)">
                      <template #icon>
                        <sdFeatherIcons v-if="!(loading && currentCountryValue === country.value)" type="send" />
                      </template>
                    </a-button>
                  </a-tooltip>
                </template>
              </sd-heading>
              <country-flag :country="country.value" size="normal" :rounded="true" />
              <span>{{country.label}}</span>
            </li>
          </ul>
        </div>
      </div>
    </contact-card-wrapper>
  </sd-cards>
</template>

<script>
import {ref} from 'vue';
import {ContactCardWrapper} from './style';
import CountryFlag from 'vue-country-flag-next';
import VueTypes from 'vue-types';
import {defineComponent} from '@vue/runtime-core';
import {requestXlsx} from '@/helpers/downloadFile';
import {requestPdf} from '@/helpers/previewFile';

export default defineComponent({
  name: 'CompanyCard',
  components: {ContactCardWrapper, CountryFlag},
  props: {
    canDownloadOrPreview: VueTypes.bool.def(false),
    companyId: VueTypes.string,
    data: VueTypes.oneOfType([VueTypes.array, VueTypes.object])
  },
  setup(props) {
    const download = (currentCountry) => {
      const params = {company_id: props.companyId, country_code: currentCountry.value};
      requestXlsx('price_lists/download', params, `${currentCountry.label} price list`);
    };
    const loading = ref(false);
    const currentCountryValue = ref(null);
    const preview = async (currentCountry) => {
      loading.value = true;
      currentCountryValue.value = currentCountry.value;
      const params = {company_id: props.companyId, country_code: currentCountry.value};
      await requestPdf('price_lists/preview', params, `${currentCountry.label} price list`);
      loading.value = false;
      currentCountryValue.value = null;
    };
    return {
      download,
      preview,
      loading,
      currentCountryValue
    };
  }
});

</script>

<style scoped>
.price-list-download {
  margin-top: 8px;
}
button{
  position: relative!important
}
</style>
