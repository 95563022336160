<template>
  <div>
    <table-style-wrapper>
      <table-wrapper class="table-responsive">
        <a-table
          :columns="columns"
          :data-source="refinedRecords"
          :rowKey="'identifier'"
          :pagination="false"
          :loading="loading"
        >
        <template #field="{record}">
          {{$t(`models.${$_.camelCase(resourceName)}.attributes.${$_.camelCase(record.field)}`)}}
        </template>
        <template #old="{record}">
          <span class='red'>
            {{record.field == 'status' && record.old ?
            $t(`models.${$_.camelCase(resourceName)}.attributes.statuses.${record.old}`) : record.old}}
          </span>
        </template>
        <template #new="{record}">
          <span class='green'>
            {{record.field == 'agent_approved_at' && record.new ?
             moment(record.new).format('DD MMM YYYY') : record.field == 'status' && record.new ?
             $t(`models.${$_.camelCase(resourceName)}.attributes.statuses.${record.new}`) : record.new}}
          </span>
        </template>
        </a-table>
      </table-wrapper>
    </table-style-wrapper>
  </div>
</template>

<script>
import {
  computed, defineComponent
} from 'vue';
import {TableStyleWrapper} from '@/components/tables/style';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import {useI18n} from 'vue-i18n';
import VueTypes from 'vue-types';
import _ from 'lodash';
import moment from 'moment';

export default defineComponent({
  components: {
    TableStyleWrapper,
    TableWrapper
  },
  props: {
    records: VueTypes.object.def({}),
    resourceName: VueTypes.string.def('')
  },
  setup(props, context) {
    const {t} = useI18n();
    const columns = computed(() => [
      {
        title: t('models.version.attributes.field'),
        dataIndex: 'field',
        slots: {customRender: 'field'}
      },
      {
        title: t('models.version.attributes.old'),
        dataIndex: 'old',
        slots: {customRender: 'old'}
      },
      {
        title: t('models.version.attributes.new'),
        dataIndex: 'new',
        slots: {customRender: 'new'}
      }
    ]);
    const refinedRecords = computed(() => {
      return _.map( _.omit(props.records, ['dimensions_and_weights']), (value, key) => {
        return {
          field: key, old: value[0], new: value[1]
        };
      });
    });


    return {
      columns,
      refinedRecords,
      moment
    };
  }
});
</script>
<style lang="scss">
.ant-table-row-cell-last {
  text-align: left !important;
}

.red {
  color: #E83557;
}

.green {
  color: #64C295;
}

</style>
