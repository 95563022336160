<template>
  <a-row :gutter="[32,0]">
    <a-col :xs="24">
      <sdCards :title="$t('views.agent.title')">
        <table-style-wrapper>
          <table-wrapper class="table-responsive">
            <a-table
              :columns="columns"
              :data-source="dataSource ? dataSource.data : []"
              :rowKey="(record) => record._jv.id"
              :pagination="false"
              :loading="loading">
              <template #countryLabel="{record}">
                {{ record.countryLabel }}
              </template>
              <template #cityLabel="{record}">
                {{getCityByCode(record.country, record.city)}}
              </template>
              <template #serviceTypes="{record}">
                <span  v-for="s in record.serviceTypes" :key="s" :class="`status-text ${serviceTypeClass(s)}`">
                  {{$t(`models.shipmentRequest.attributes.shipmentTypes.${s}`)}}
                </span>
              </template>
              <template #action="{record}">
                <div class="table-actions">
                  <sd-button class="btn-icon"
                            type="default"
                            shape="circle"
                            :tooltip="record.preferred ? $t('actions.remove') : $t('actions.add')"
                            @click="record.preferred ? unpreferred(record._jv.id): preferred(record._jv.id)">
                    <sd-feather-icons :type="record.preferred ? 'x-circle' : 'plus-circle'" size="16"/>
                  </sd-button>
                </div>
              </template>
            </a-table>
          </table-wrapper>
        </table-style-wrapper>
      </sdCards>
    </a-col>
  </a-row>
</template>

<script>
import {
  computed, defineComponent
} from 'vue';
import {usePagination} from 'vue-request';
import {useStore} from 'vuex';
import {TableStyleWrapper} from '../tables/style';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import {companyServiceTypesColors} from '@/helpers/companyServiceTypesColors';
import {useI18n} from 'vue-i18n';
import {getCityByCode} from '@/helpers/citiesOptions';

export default defineComponent({
  name: 'AgentsTable',
  components: {
    TableStyleWrapper,
    TableWrapper
  },
  setup() {
    const {getters, dispatch} = useStore();
    const {t} = useI18n();

    const columns = computed(() => [
      {
        title: t('models.company.attributes.name'),
        dataIndex: 'name'
      },
      {
        title: t('models.company.attributes.serviceTypes'),
        slots: {customRender: 'serviceTypes'}
      },
      {
        title: t('models.company.attributes.country'),
        slots: {customRender: 'countryLabel'}
      },
      {
        title: t('models.company.attributes.city'),
        slots: {customRender: 'cityLabel'}
      },
      {
        title: t('models.company.attributes.phone'),
        dataIndex: 'phone'
      },
      {
        title: 'Preferred',
        key: 'action',
        slots: {customRender: 'action'}
      }
    ]);

    const serviceTypeClass = (serviceType) => {
      return serviceType === 'EOR' ? 'danger' : 'secondary';
    };

    const getAgents = (params) => dispatch('getClientAgents', params);

    const {
      data: dataSource, loading
    } = usePagination(getAgents, {
      formatResult: () => getters.agents
    });

    const preferred = (agentId) => {
      dispatch('preferredAgent', agentId);
    };
    const unpreferred = (agentId) => {
      dispatch('unpreferredAgent', agentId);
    };

    return {
      columns,
      dataSource,
      loading,
      serviceTypeClass,
      preferred,
      unpreferred,
      companyServiceTypesColors,
      getCityByCode
    };
  }
});
</script>
