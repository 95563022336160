<template>
  <div>
    <table-style-wrapper>
      <table-wrapper class="table-responsive">
        <a-table
          :columns="columns"
          :data-source="refinedRecords"
          :rowKey="'identifier'"
          :pagination="false"
          :loading="loading"
          :rowClassName="(record, index) => (index >= oldDataLength ? 'new-row' : 'old-row')"
        >
        </a-table>
      </table-wrapper>
    </table-style-wrapper>
  </div>
</template>

<script>
import {
  computed, defineComponent
} from 'vue';
import {TableStyleWrapper} from '@/components/tables/style';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import {useI18n} from 'vue-i18n';
import VueTypes from 'vue-types';
import _ from 'lodash';

export default defineComponent({
  components: {
    TableStyleWrapper,
    TableWrapper
  },
  props: {
    records: VueTypes.object.def({})
  },
  setup(props, context) {
    const {t} = useI18n();
    const oldDataLength = computed(() => _.keys(props.records[0]).length);
    const columns = computed(() =>[
      {
        title: t('models.shipment.attributes.length'),
        dataIndex: 'length'
      },
      {
        title: t('models.shipment.attributes.width'),
        dataIndex: 'width'
      },
      {
        title: t('models.shipment.attributes.height'),
        dataIndex: 'height'
      },
      {
        title: t('models.shipment.attributes.dimensionsUnit'),
        dataIndex: _.snakeCase('dimensionsUnit')
      },
      {
        title: t('models.shipment.attributes.weight'),
        dataIndex: 'weight'
      },
      {
        title: t('models.shipment.attributes.weightUnit'),
        dataIndex: _.snakeCase('weightUnit')
      }
    ]);
    const refinedRecords = computed(() => {
      const records = [];
      _.each(props.records, (version) => {
        _.each(version, (record) => {
          records.push(record);
        });
      });
      return records;
    });


    return {
      columns,
      refinedRecords,
      oldDataLength
    };
  }
});
</script>
<style lang="scss">
.ant-table-row-cell-last {
  text-align: left !important;
}

.old-row td {
  color: red !important;
}

.new-row td{
  color: green !important;
}

</style>
