<template>
  <a-row :gutter="[32,0]">
    <a-col class="mt-10" :xs="24" v-if="rejectionReason">
      <sdCards headless>
        <h3>{{$t('models.companyProfileStep.attributes.rejectionReason')}}:</h3>
        {{rejectionReason}}
      </sdCards>
    </a-col>
    <a-col :xs="24">
      <sdCards :headless="!showTitle" :title="showTitle ? $t('models.productItem.entity', 2) : ''">
        <table-style-wrapper>
          <table-wrapper class="table-responsive">
            <a-table
              :columns="columns"
              :data-source="dataSource ? dataSource : []"
              :rowKey="(record) => record._jv.id"
              :loading="loading"
              :pagination="false"
            >
              <template #descriptionOfGoods="{ record }">
                <div class="preserve-multiline">{{record.descriptionOfGoods}}</div>
              </template>
              <template #productCategory="{record}">
                {{record._jv.relationships.productCategory.attributes.value}}
              </template>
              <template #comment="{ record }">
                <div class="preserve-multiline">{{record.comment}}</div>
              </template>

              <template #dataSheet="{record}">
                <div v-if="record.dataSheet" class="table-actions">
                  <sd-button
                    class="btn-icon"
                    type="default"
                    shape="circle"
                    :tooltip="$t('actions.download')"
                    @click="download(record)">
                    <sd-featherIcons type="download" size="16" />
                  </sd-button>
                </div>
              </template>
            </a-table>
          </table-wrapper>
        </table-style-wrapper>
      </sdCards>
    </a-col>
  </a-row>
</template>

<script>
import {computed, defineComponent} from 'vue';
import {usePagination} from 'vue-request';
import {useStore} from 'vuex';
import {TableStyleWrapper} from '../tables/style';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import {useI18n} from 'vue-i18n';
import VueTypes from 'vue-types';
import {downloadFile} from '@/helpers/downloadFile';

export default defineComponent({
  name: 'ProductItemsTable',
  components: {
    TableStyleWrapper,
    TableWrapper
  },
  props: {
    companyId: VueTypes.string,
    showTitle: VueTypes.bool.def(false),
    rejectionReason: VueTypes.string
  },
  setup(props) {
    const {
      getters,
      dispatch
    } = useStore();
    const {t} = useI18n();

    const isOwnCompany = computed(() => props.companyId === getters.currentUser._jv.relationships?.company?._jv?.id);

    const columns = [
      {
        title: t('models.productItem.attributes.hsCode'),
        dataIndex: 'hsCode'
      },
      {
        title: t('models.productItem.attributes.productNumber'),
        dataIndex: 'productNumber'
      },
      {
        title: t('models.productItem.attributes.productCategory'),
        dataIndex: 'productCategoryId',
        slots: {customRender: 'productCategory'}
      },
      {
        title: t('models.productItem.attributes.countryOfOrigin'),
        dataIndex: 'countryOfOriginName'
      },
      {
        title: t('models.productItem.attributes.itemType'),
        dataIndex: 'itemType'
      },
      {
        title: t('models.productItem.attributes.descriptionOfGoods'),
        dataIndex: 'descriptionOfGoods',
        width: '500px',
        slots: {customRender: 'descriptionOfGoods'}
      },
      {
        title: t('models.productItem.attributes.comment'),
        dataIndex: 'comment',
        width: '500px',
        slots: {customRender: 'comment'}
      },
      {
        title: t('models.productItem.attributes.dataSheet'),
        slots: {customRender: 'dataSheet'}
      }
    ];

    const getProductItems = () => isOwnCompany.value ? dispatch('getProductItems') : dispatch('getCompanyProductItems', props.companyId);

    const {
      data: dataSource,
      loading
    } = usePagination(getProductItems, {
      formatResult: () => getters.productItems
    });

    const download = (record) => downloadFile(record.dataSheet.filename, record.dataSheet.url);

    return {
      columns,
      dataSource,
      loading,
      download
    };
  }
});
</script>
