<template>
  <div>
    <table-style-wrapper>
      <table-wrapper class="table-responsive">
        <a-table
          :columns="visibleColumns"
          :data-source="dataSource ? dataSource.data : []"
          :rowKey="'identifier'"
          :pagination="pagination"
          :loading="loading"
          expandRowByClick
          @change="handleTableChange"
        >
          <template #updatedAt="{record}">
            {{record.updatedAt ? moment(record.updatedAt).format('DD MMM YYYY') : ''}}
          </template>
          <template #status="{record}">
            <span :class="`status-text ${agentShipmentStatusColors[$_.camelCase(record.status)]}`">
              {{$t(`models.shipment.attributes.statuses.${record.status}`)}}
            </span>
          </template>
          <template #expandedRowRender="{ record }">
            <versions-changes :records="record.objectChanges" :resourceName="resourceName"></versions-changes>
            <dimensions-changes v-if="record.objectChanges.dimensions_and_weights"
            :records="record.objectChanges.dimensions_and_weights">
            </dimensions-changes>
          </template>
        </a-table>
      </table-wrapper>
    </table-style-wrapper>
  </div>
</template>

<script>
import {
  computed, defineComponent, watch
} from 'vue';
import {TableStyleWrapper} from '../tables/style';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import {useStore} from 'vuex';
import {useI18n} from 'vue-i18n';
import {usePagination} from 'vue-request';
import VueTypes from 'vue-types';
import moment from 'moment';
import {agentShipmentStatusColors} from '@/helpers/agentShipmentStatusColors';
import VersionsChanges from '@/components/shared/table/VersionsChanges';
import DimensionsChanges from '@/components/shared/table/DimensionsChanges';

export default defineComponent({
  components: {
    TableStyleWrapper,
    TableWrapper,
    VersionsChanges,
    DimensionsChanges
  },
  props: {
    resourceName: VueTypes.string.def(''),
    resourceId: VueTypes.string.def('')
  },
  setup(props, context) {
    const {t} = useI18n();
    const {
      getters,
      dispatch
    } = useStore();
    const columns = computed(() => [
      {
        title: t('models.user.attributes.userName'),
        dataIndex: 'userName',
        visible: true
      },
      {
        title: t('models.version.attributes.date'),
        dataIndex: 'updatedAt',
        slots: {customRender: 'updatedAt'},
        visible: true
      },
      {
        title: t('models.shipment.attributes.shipmentStatus'),
        dataIndex: 'status',
        slots: {customRender: 'status'},
        visible: props.resourceName === 'shipment'
      }
    ]);
    const visibleColumns = computed(() => columns.value.filter((column) => column.visible));

    const getHistory = (params) => dispatch('getHistory', {
      resourceType: props.resourceName,
      resourceId: props.resourceId,
      params
    });

    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize
    } = usePagination(getHistory, {
      formatResult: () => getters.versions,
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'perPage',
        totalKey: 'totalEntries'
      }
    });

    const pagination = computed(() => ({
      total: getters.versions?.totalEntries,
      current: current.value,
      pageSize: pageSize.value
    }));

    const handleTableChange = (pag, sorter) => {
      run({
        per_page: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order
      });
    };

    watch(props.resourceId, (value) => {
      if (value) {
        handleTableChange({}, {});
      }
    }, {deep: true});

    return {
      dataSource,
      pagination,
      loading,
      visibleColumns,
      moment,
      handleTableChange,
      agentShipmentStatusColors
    };
  }
});
</script>
<style lang="scss">
.ant-table-row-cell-last {
  text-align: left !important;
}

</style>
