<template>
  <company-details-wrapper>
    <app-header
      :routes="routes"
      :buttons="buttons"
      @header-action="headerAction($event)"
    />
    <Main>
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="details" :tab="$t('views.company.details')">
          <a-row :gutter="25">
            <a-col :xs="24" :sm="24" :md="24" :xl="6" :xxl="5">
              <company-card :company="company"/>
            </a-col>
            <a-col :xxl="19" :xl="18" :md="24" :sm="24" :xs="24">
              <sd-cards headless>
                <a-row>
                  <a-col v-for="(state, key) in states" :key="key" :sm="24" :md="12" class="state-single">
                    <div v-if="!state.hidden" :class="state.color">
                      <a>
                        <sd-feather-icons :type="state.icon" size="25"/>
                      </a>
                    </div>
                    <div v-if="!state.hidden">
                      <sd-heading v-if="state.isFile && state.url" as="h5">
                        <a @click="downloadFile(state.value, state.url)">
                          {{ state.value }}
                          <sdFeatherIcons type="download" />
                        </a>
                      </sd-heading>
                      <sd-heading v-else as="h5">{{ state.value }}</sd-heading>
                      <p>{{ state.label }}</p>
                    </div>
                  </a-col>
                </a-row>
              </sd-cards>
            </a-col>
            <a-col v-if="$can('readUsers', 'company')"
                  :xs="24" :sm="24" :md="24" :xl="isClientCompany ? 24 : 18" :xxl="isClientCompany ? 24 : 12">
              <a-row type="flex" justify="start">
                <a-col :xs="24" class="table-search">
                  <a-row type="flex" justify="start">
                    <a-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" :xxl="6" class="mt-10">
                      <filter-bar v-model:filterValue="userStatus"
                                  :options="filterBarOptions"
                                  i18nPath="models.user.status"/>
                    </a-col>
                    <a-col :xs="24" :sm="12" :md="18" :lg="18" :xl="18" :xxl="18">
                      <a-row type="flex" justify="end">
                        <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" :xxl="12" class="mt-10 mr-10">
                          <a-select v-model:value="selectedPermissions.value"
                                    :allowClear="true"
                                    :filterOption="permissions"
                                    :placeholder="$t('layout.users.invite.permissions')"
                                    :maxTagCount="1"
                                    :show-arrow="$_.isEmpty(selectedPermissions.value)"
                                    :showSearch="false"
                                    mode="multiple"
                                    class="permissions-filter">
                            <a-select-option v-for="permission in permissions" :key="permission.id"
                            :value="permission.id">
                              <span>{{ permission.displayName }}</span>
                            </a-select-option>
                          </a-select>
                        </a-col>
                        <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" :xxl="8" class="mt-10">
                          <a-input-search v-model:value="searchValue" :placeholder="$t('actions.search')"/>
                        </a-col>
                      </a-row>
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :xxl="24" :xl="24" :md="24" :xs="24">
                  <sd-cards :title="$t('layout.companies.users')">
                    <users-table v-if="company._jv?.id === companyId"
                                :company-id="companyId"
                                :key="companyId"
                                :permissions="selectedPermissions"
                                :searchValue="searchValue"
                                :userStatus="userStatus"/>
                  </sd-cards>
                </a-col>
              </a-row>
            </a-col>
            <a-col v-if="!isClientCompany" :xs="24" :sm="24" :md="12" :xl="!$can('readUsers', 'company') ? 12 : 6"
                  class="mt-10">
              <product-categories :data="company.productCategories"/>
            </a-col>
            <a-col v-if="!isClientCompany" :xs="24" :sm="24" :md="12" :xl="18"
                  :xxl="!$can('readUsers', 'company') ? 12 : 6"
                  class="mt-10">
              <countries-coverage
                :data="company.labelledCountriesCoverage"
                :canDownloadOrPreview="!isClient"
                :companyId="companyId"
              />
            </a-col>
            <a-col v-if="isClientCompany" :xs="24">
              <product-items-table
                v-if="company._jv"
                :key="company._jv.id"
                :companyId="company._jv.id"
                :showTitle="true"/>
            </a-col>
            <a-col v-if="isClient" :xs="24">
              <agents-table/>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane v-if="$can('read', 'allHistory')" key="history" :tab="$t('views.company.history')">
          <a-col :xxl="24" :xl="24" :md="24" :xs="24">
            <sd-cards :title="$t('layout.shipmentRequest.history')">
              <versions-table resourceName="company" :resourceId="companyId"/>
            </sd-cards>
          </a-col>
        </a-tab-pane>
      </a-tabs>
    </Main>
  </company-details-wrapper>
</template>
<script>
import {
  defineComponent, computed, ref, watch, reactive
} from 'vue';
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {useI18n} from 'vue-i18n';
import {CompanyDetailsWrapper} from './style';
import {Main} from '@/components/shared/styledComponents/Main';
import UsersTable from '@/components/companies/UsersTable';
import FilterBar from '@/components/tables/FilterBar';
import CompanyCard from '@/components/companies/CompanyCard';
import CountriesCoverage from '@/components/companies/CountriesCoverage';
import ProductCategories from '@/components/companies/ProductCategories';
import {useAbility} from '@casl/vue';
import {downloadFile} from '@/helpers/downloadFile';
import ProductItemsTable from '@/components/companyVerification/ProductItemsTable';
import AgentsTable from '@/components/companyVerification/AgentsTable';
import VersionsTable from '@/components/shipments/VersionsTable';

export default defineComponent({
  name: 'companyDetails',
  components: {
    CompanyDetailsWrapper,
    Main,
    UsersTable,
    FilterBar,
    CompanyCard,
    CountriesCoverage,
    ProductCategories,
    ProductItemsTable,
    AgentsTable,
    VersionsTable
  },
  setup() {
    const router = useRouter();
    const {
      dispatch, getters, state
    } = useStore();
    const {t} = useI18n();

    const isClient = computed(() => state.session.userType === 'ClientUser');
    const companyId = computed(() => router.currentRoute.value.name === 'CompanyProfile' ? router.currentRoute.value.params.companyId : getters.currentUser?._jv?.relationships?.company?._jv?.id);
    const company = computed(() => router.currentRoute.value.name === 'CompanyProfile' ? getters.company : getters.currentUser?._jv?.relationships?.company);
    const isClientCompany = computed(() => company?.value?.type === 'Client');
    const activeKey = ref('details');

    const filterBarOptions = ['all', 'active', 'archived'];
    const userStatus = ref('all');

    const getCompany = async () => {
      dispatch('toggleLoading', true);
      await dispatch('getCompany', companyId.value);
      isClientCompany?.value ? dispatch('getClientPermissions') : dispatch('getAgentPermissions');
      dispatch('toggleLoading', false);
    };

    const selectedPermissions = reactive({value: []});
    const permissions = computed(() => isClientCompany?.value ? getters.clientPermissions : getters.agentPermissions);
    const searchValue = ref('');

    watch(companyId, (value) => {
      if (value && (router.currentRoute.value.name === 'CompanyProfile')) {
        getCompany();
      }
    }, {
      immediate: true,
      deep: true
    });

    const routes = computed(() => {
      if (router.currentRoute.value.name === 'CompanyProfile') {
        return [{
          name: isClientCompany?.value ? 'BackOfficeClientCompanies' : 'BackOfficeAgentCompanies',
          breadcrumbName: 'layout.companies.index'
        },
        {
          name: 'CompanyDetails',
          params: {companyId: companyId.value},
          breadcrumbName: 'views.company.details'
        }];
      }
      return [];
    });

    const states = computed(() => {
      return [
        {
          color: 'color-secondary',
          icon: 'user',
          value: company?.value?.contactPersonName || '--',
          label: t('models.company.attributes.contactPersonName')
        },
        {
          color: 'color-secondary',
          icon: 'user-check',
          value: company?.value?.contactPersonPosition || '--',
          label: t('models.company.attributes.contactPersonPosition')
        },
        {
          color: 'color-success',
          icon: 'key',
          value: company?.value?.registrationNumber || '--',
          label: t('models.company.attributes.registrationNumber')
        },
        {
          isFile: true,
          color: 'color-success',
          icon: 'award',
          value: company?.value?.businessRegistrationCertificate?.filename || '--',
          url: company?.value?.businessRegistrationCertificate?.url,
          label: t('models.company.attributes.businessRegistrationCertificate')
        },
        {
          isFile: true,
          color: 'color-warning',
          icon: 'file-text',
          value: company?.value?.tradeLicense?.filename || '--',
          url: company?.value?.tradeLicense?.url,
          label: t('models.company.attributes.tradeLicense')
        },
        {
          color: 'color-warning',
          icon: 'coffee',
          value: company?.value?.serviceTypes?.join('/') || '--',
          label: t('models.company.attributes.serviceTypes'),
          hidden: company?.value?.type === 'Client'
        }];
    });

    const {can} = useAbility();
    const buttons = computed(() => {
      return [{
        action: 'edit',
        icon: 'edit',
        label: 'actions.edit',
        visible: can('edit', 'company'),
        disabled: !can('edit', company?.value),
        tooltip: can('edit', company?.value) ? '' : t('messages.help.companyInReview'),
        tooltipPlacement: 'left'
      }, {
        action: 'verify',
        icon: 'check-circle',
        label: 'views.profileVerification.verificationProcess',
        visible: can('verify', company?.value)
      }];
    });

    const headerAction = (action) => headerActions[action]();
    const headerActions = {
      verify: () => {
        if (company?.value?.status !== 'in_review') {
          dispatch('updateCompany', {
            form: {status: 'in_review'},
            id: company?.value?._jv.id,
            hideToaster: true
          });
        }
        router.push({name: 'BackOfficeProfileVerification', params: {companyId: company?.value?._jv.id}});
      },
      edit: () => router.push({name: `${company?.value?.type}Edit`})
    };

    return {
      companyId,
      isClient,
      isClientCompany,
      routes,
      selectedPermissions,
      permissions,
      searchValue,
      filterBarOptions,
      userStatus,
      company,
      states,
      buttons,
      activeKey,
      headerAction,
      downloadFile
    };
  }
});
</script>

<style lang="scss" scoped>
h1 a {
  background-color: inherit !important;
  display: inline !important;
}

h1 {
  overflow-wrap: anywhere !important;
}

.state-single:last-child {
  margin-bottom: 25px !important;
}
</style>
