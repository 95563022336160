<template>
  <sd-cards headless>
    <contact-card-wrapper>
      <div class="contact-card">
        <figure>
          <figcaption>
            <h3>{{ $t('models.company.attributes.productCategories') }}</h3>
          </figcaption>
        </figure>
        <div class="user-info">
          <ul>
            <li v-for="category in data" :key="category">
              <span>{{category.value}}</span>
            </li>
          </ul>
        </div>
      </div>
    </contact-card-wrapper>
  </sd-cards>
</template>

<script>
import {ContactCardWrapper} from './style';
import VueTypes from 'vue-types';
import {defineComponent} from '@vue/runtime-core';

export default defineComponent({
  name: 'ProductCategoriesCard',
  components: {ContactCardWrapper},
  props: {
    data: VueTypes.array.required
  }
});

</script>
