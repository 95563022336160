<template>
  <table-style-wrapper>
    <table-wrapper class="table-responsive">
      <a-table :columns="columns"
               :data-source="dataSource ? dataSource.data : []"
               :rowKey="(record) => record._jv.id"
               :pagination="pagination"
               :loading="loading"
               @change="handleTableChange">
        <template #fullName="{record}">
          {{record.firstName + ' ' + record.lastName}}
        </template>
        <template #status="{record}">
            <span :class="`status-text ${$_.isEmpty(record.deletedAt) ? 'success' : 'danger'}`">
              {{$_.isEmpty(record.deletedAt) ? "Active" : "Archived"}}
            </span>
        </template>
        <template #action="{record}">
          <div class="table-actions">
            <router-link :to="userProfile(record)" >
              <sd-button class="btn-icon"
                         type="link"
                         shape="circle"
                         :tooltip="$t('actions.view')">
                <sd-feather-icons type="eye" :size="16"/>
              </sd-button>
            </router-link>
          </div>
        </template>
      </a-table>
    </table-wrapper>
  </table-style-wrapper>
</template>

<script>
import {
  computed, defineComponent, reactive, ref, watch
} from 'vue';
import {usePagination} from 'vue-request';
import {useStore} from 'vuex';
import {TableStyleWrapper} from '../tables/style';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import moment from 'moment';
import {useI18n} from 'vue-i18n';
import VueTypes from 'vue-types';
import _ from 'lodash';

export default defineComponent({
  name: 'CompanyUsersTable',
  props: {
    companyId: VueTypes.string.required,
    searchValue: VueTypes.string.def(''),
    permissions: VueTypes.object.def({value: []}),
    userStatus: VueTypes.string
  },
  components: {
    TableStyleWrapper,
    TableWrapper
  },
  setup(props, context) {
    const {
      state,
      getters,
      dispatch
    } = useStore();
    const {t} = useI18n();

    const columns = computed(() => [{
      title: t('models.user.attributes.fullName'),
      dataIndex: 'firstName',
      slots: {customRender: 'fullName'}
    }, {
      title: t('models.user.attributes.email'),
      dataIndex: 'email'
    }, {
      slots: {customRender: 'status'},
      dataIndex: 'deletedAt'
    }, {
      slots: {customRender: 'action'}
    }
    ]);

    const getUsers = (params) => {
      return dispatch('getCompanyUsers', {params: params, companyId: props.companyId});
    };

    const filtersState = reactive({
      search: undefined,
      permissionIds: undefined,
      deleted: undefined
    });

    const remove = async (data) => {
      await dispatch('deleteUsers', data);
      mutate(getters.users);
    };

    const filtersChanged = ref(false);
    watch(() => props.searchValue, () => {
      filtersState.search = props.searchValue;
      filtersChanged.value = true;
      if (props.searchValue.length === 0 || props.searchValue.length >= 3) {
        setTimeout(() => {
          handleTableChange(pagination, filtersState, {});
        }, 100);
        context.emit('searched');
      }
    });

    watch(() => props.permissions.value.length, () => {
      filtersState.permissionIds = props.permissions.value;
      filtersChanged.value = true;
      handleTableChange(pagination, filtersState, {});
      context.emit('searched');
    });

    watch(() => props.userStatus, () => {
      filtersChanged.value = true;
      filtersState.deleted = props.userStatus === 'all' ? undefined : props.userStatus === 'archived';
      handleTableChange(pagination, filtersState, {});
      context.emit('searched');
    });

    const {
      data: dataSource,
      run,
      mutate,
      loading,
      current,
      pageSize
    } = usePagination(getUsers, {
      formatResult: () => getters.users,
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'perPage',
        totalKey: 'totalEntries'
      }
    });

    const pagination = computed(() => ({
      total: getters.users?.totalEntries,
      current: current.value,
      pageSize: pageSize.value
    }));

    const handleTableChange = (pag, filters, sorter) => {
      const filterParams = {};
      _.forEach(filtersState, (field, key) => {
        filtersState[key] = filters[key] || field;
        if (filtersState[key] !== undefined) filterParams[_.snakeCase(key)] = filtersState[key];
      });
      run({
        per_page: pag.pageSize,
        page: filtersChanged.value ? 1 : pag.current,
        ...filterParams
      });
      filtersChanged.value = false;
    };

    const userProfile = (record) => {
      return {
        name: state.session.currentUser.type + 'Profile',
        params: {userId: record?._jv?.id === state.session.currentUser?._jv?.id ? 'me' : record?._jv?.id}
      };
    };

    return {
      columns,
      dataSource,
      pagination,
      loading,
      moment,
      handleTableChange,
      remove,
      userProfile
    };
  }
});
</script>
